/*=========================================================================================
  File Name: i18nData.js
  Description: Imports i18n data
==========================================================================================*/

import TranslationDataEN from './lang/en/locale.en'
import TranslationDataKR from './lang/kr/locale.kr'
import TranslationDataCH from './lang/ch/locale.ch'

export default {
	"en-US": TranslationDataEN,
	"ko-KR": TranslationDataKR,
	"ch-CH": TranslationDataCH
}
