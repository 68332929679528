export default {
  lang: {
    english: 'English',
    korean: '한국어',
    chinese: '中文',
  },
  publicImgUrl: '/assets/images/',
  level: {
    1: 'Level 1',
    2: 'Level 2',
    3: 'Level 3',
    4: 'Level 4',
    5: 'VIP',
  },
  gameList: {
    ALL: 'ALL',
    DG: 'DREAM',
    DGCROWN: 'DREAM CROWN',
    EVO: 'EVO',
    OGP: 'OGPLUS',
    88: '88',
    CMB: 'CMB',
    CGY: 'CAG',
    LMC: 'LMC',
    AG: 'ASIA',
    CLIB: 'CLIB',
    LMCLUB: 'LMCLUB',
    MONEY_TRANSFER: 'TRANSACTION',
  },
  lobby: {
    balanceLabel: 'Balance',
    gameType: {
      all: 'All',
      baccarat: 'Baccarat',
      speedbaccarat: 'Speed Baccarat',
      lightning: 'Lightning',
      koreantrader: 'Korean trader',
      dragontiger: 'Dragon Tiger',
      bullbull: 'Bull Bull',
      KOREANBAC: 'KOREAN TRADER',
      NORMAL: 'NORMAL',
      SPEED: 'SPEED BACCARAT',
      LIGHTNING: 'LIGHTNING'
    },
    gameCategory: {
      SOLAIRE: 'Solaire',
      CAGAYAN: 'Cagayan',
      STOTSENBERG: 'Stotsenberg',
      OKADA: 'OKADA',
      RESORTSWORLD: 'Resorts World',
      WM: 'WM',
      EURO: 'EURO',
      FLAGSHIP: 'FLAGSHIP',
      CROWN: 'CROWN',
      GLOBAL: 'GLOBAL'
    },
    minimumBet: 'Minimum Bet:',
    maximumBet: 'Maximum Bet:',
    playerBetLimit: 'Player Min ~ Max:',
    bankerBetLimit: 'Banker Min ~ Max:',
    tieBetLimit: 'Tie Min ~ Max:',
    pairBetLimit: 'Pair Min ~ Max:',
    baccarat: 'Enter Room',
    cardShuffle: "Cards shuffle",
  },
  gameroom: {
    closeTextHeader: 'CLOSED',
    closeTextTop: 'ROOM IS CURRENTLY CLOSED.',
    closeTextBot: '',
    betLimitText: 'Bet Limit: ',
    playerBetLimitText: 'Player Limit: ',
    tieBetLimitText: 'Tie Limit: ',
    bankerBetLimitText: 'Banker Limit: ',
    pairBetLimitText: 'Pair Limit: ',
    solaire: "Solaire",
    cagayan: "cagayan"
  },
  shoeArea: {
    banker: 'Banker',
    player: 'Player',
    dragon: 'Dragon',
    tiger: 'Tiger',
  },
  game: {
    balance: 'Balance',
    totalBet: 'Total Bet',
    player: 'PLAYER',
    playerPair: 'P.PAIR',
    banker: 'BANKER',
    bankerPair: 'B.PAIR',
    dtplayer: 'Tiger',
    dtbanker: 'Dragon',
    dttie: 'Tie',
    tie: 'TIE',
    p1: 'PLAYER1',
    p2: 'PLAYER2',
    p3: 'PLAYER3',
    b1: 'BANKER1',
    b2: 'BANKER2',
    b3: 'BANKER3',
    p1b: 'P1 B.B',
    p2b: 'P2 B.B',
    p3b: 'P2 B.B',
    b1b: 'B1 B.B',
    b2b: 'B2 B.B',
    b3b: 'B3 B.B',
    playerRate: '1:1',
    playerPairRate: '1:11',
    bankerRate: '1:0.95',
    bankerPairRate: '1:11',
    tieRate: '1:8',
    tieRateDt: '1:11',
    tigerRate: '1:0.97',
    dragonRate: '1:0.97',
    sendBet: 'Sent Bet',
    clearBet: 'Clear Bet',
    cancelBet: 'Cancel Bet',
    moveToLobyButton: 'Change Lobby',
    bettingMessage: {
      open: 'Betting Open',
      closed: 'Betting Closed',
      resultshow: 'Result Showing',
      shuffling: 'Shuffling',
    },
    dragon: 'Dragon',
    tiger: 'Tiger',
    bettingNotification: {
      bettingSuccessful: 'Bet Successful',
      bettingFailed: 'Betting Failed',
      playerBankerConflict: 'Player & Banker Conflict',
      bettingCleared: 'Betting Cleared',
      bettingCancelled: 'Betting Cancelled',
      bettingCancelFail: 'Fail To Cancel Bet',
      ROOM_NOT_BET_OPEN: 'Betting Currently Closed',
      NO_BET: 'No bet',
      BET_CANCEL_SUCCESS: 'Betting Cancelled',
      INSUFFICIENT_BALANCE: 'Insufficient Balance',
      INVALID_BETAMOUNT_DUE_TO_LIMIT: 'Bet Limit Reached',
      ERROR_OCCURED: 'An Error Occured',
    },
    chipCurrency: {
      KRW: 'KRW',
      USD: 'USD',
      KRW1000: '천',
      KRW10000: '만',
      USD1000: 'K',
    },
    cardResult: {
      jack: 'J',
      queen: 'Q',
      king: 'K',
      playerResult: 'Player',
      bankerResult: 'Banker',
      tieResult: 'Tie',
      leftPanelLabel: 'P',
      rightPanelLabel: 'B',
      leftPanelLabelDT: 'Tr',
      rightPanelLabelDT: 'Dr',
    },
    result: {
      banker: 'banker',
      bankerBankerPair: 'bankerBankerPair',
      bankerPlayerPair: 'bankerPlayerPair',
      bankerBankerPlayerPair: 'bankerBankerPlayerPair',
      player: 'player',
      playerBankerPair: 'playerBankerPair',
      playerPlayerPair: 'playerPlayerPair',
      playerBankerPlayerPair: 'playerBankerPlayerPair',
      tie: 'tie',
      tieBankerPair: 'tieBankerPair',
      tiePlayerPair: 'tiePlayerPair',
      tieBankerPlayerPair: 'tieBankerPlayerPair',
    },
    errorMessage: {
      BANKER_BET_CONFLICT: 'Cannot bet alongside Player',
      PLAYER_BET_CONFLICT: 'Cannot bet alongside Banker',
      BET_CLOSED: 'Bet is closed',
      INSUFFICIENT_FUNDS: 'Insufficient funds',
      NO_BET: 'No bet',
      INVALID_BETAMOUNT_DUE_TO_LIMIT: 'Bet limit reached',
      CANCEL_OVERTIME: 'CANCEL PERIOD OVER',
    },
    video: {
      marqueeText:
        '※Depending on the local weather and internet conditions, the video may not be in sync. Please use the update button at the bottom of the video. All results will be processed based on the store below the video.※',
    },
    tableSetting: 'Table setting',
    moveToLoby: 'Move to lobby',
    changeTable: 'Change table',
    liveBaccarat: 'Live Baccarat',
  },
  betHistory: {
    round: 'Round',
    table: 'Order',
    betHistoryTitle: 'Bet History',
    Username: 'Username',
    Game: 'Game',
    Bet: 'Bet',
    BetAmount: 'BetAmount',
    Status: 'Status',
    WinAmount: 'WinAmount',
    Result: 'Result',
    Card: 'Card',
    BetBefore: 'BetBefore',
    BetAfter: 'BetAfter',
    CreatedAt: 'CreatedAt',
    UnprocessedState: 'Waiting',
    BetWinState: 'Win',
    BetLoseState: 'Lose',
    BetTieState: 'Tie',
    BetRefundState: 'Refunded',
    show: 'View',
    showDetails: 'Show',
  },
  gameState: {
    readyBetting: 'Game ready',
    nextBetting: 'You can bet from the next round.',
    startBetting: 'Place a bet',
    stopBetting: 'Bets are over',
    playerWin: 'Player won',
    bankerWin: 'Banker won',
    tieWin: 'Tie won',
  },
};
