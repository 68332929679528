/*=========================================================================================
  File Name: router.js
  Description: Vue Router
==========================================================================================*/

import Vue from 'vue';
import Router from 'vue-router';

// Register Router
Vue.use(Router);

/**
 * Pages
 *
 *
 */
const LoadingScreen = () => import('../views/common/pc/LoadingScreen');
const GameLobby = () => import('../views/common/pc/GameLobby');
const GameScreen = () => import('../views/baccarat/pc/GameScreen');
const GameBullScreen = () => import('../views/bull/pc/GameScreen');
const MobileGameScreen = () => import('../views/baccarat/mobile/GameScreen');
const SessionExpired = () => import('../views/common/SessionExpired');
const PageWarning = () => import('../views/common/PageWarning');

/** Dragon Tiger **/
const GameScreenDt = () => import('../views/baccarat/pc/GameScreenDt');
const GameScreenMobileDt = () =>
  import('../views/baccarat/mobile/GameScreenDt');

/** Defensil Mobile Version **/
const MobileLoadingScreen = () =>
  import('../views/common/mobile/MobileLoadingScreen');
const GameLobbyMobile = () => import('../views/common/mobile/GameLobby.vue');

const MobileBullGameScreen = () => import('../views/bull/mobile/GameScreen');

/**
 * Router Configuration
 *
 */
export default new Router({
  linkActiveClass: 'active',
  mode: 'history',
  linkExactActiveClass: '',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  /**
   * Routes
   *
   */
  routes: [
    {
      path: '/launch/h',
      alias: '/',
      name: 'Game',
      component: LoadingScreen,
      props: (route) => ({ token: route.query.token, game: route.query.game, table_id: route.query.table_id }),
    },
    {
      path: '/lobby',
      name: 'GameLobby',
      component: GameLobby,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/game/:code/:roomno',
      name: 'GameRoom',
      component: GameScreen,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/game/bull/:code/:roomno',
      name: 'GameBullScreen',
      component: GameBullScreen,
    },
    {
      path: '/m/game/:code/:roomno',
      name: 'GameMobileRoom',
      component: MobileGameScreen,
      meta: {},
    },
    {
      path: '/m/bull/game/:code/:roomno',
      name: 'GameBullRoom',
      component: MobileBullGameScreen,
      meta: {},
    },
    {
      path: '/session-expired',
      name: 'SessionExpired',
      component: SessionExpired,
    },
    {
      path: '/page-warning',
      name: 'PageWarning',
      component: PageWarning,
    },

    // Dragon Tiger links
    {
      path: '/game/dt/:code/:roomno',
      name: 'GameRoomDt',
      component: GameScreenDt,
      meta: {
        requiresAuth: true,
      },
    },

    // Mobile links
    {
      path: '/m/launch/h',
      alias: '/m',
      name: 'GameMobile',
      component: MobileLoadingScreen,
      props: (route) => ({ token: route.query.token, game: route.query.game }),
    },
    {
      path: '/m/lobby',
      name: 'GameLobbyMobile',
      component: GameLobbyMobile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/m/game/dt/:code/:roomno',
      name: 'GameScreenMobileDt',
      component: GameScreenMobileDt,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});
