export default {
  lang: {
    english: 'English',
    korean: '한국어',
    chinese: '中文',
  },
  uiLabels: {
    close: 'CLOSE',
  },
  publicImgUrl: '/assets/images/',
  level: {
    1: '레벨1',
    2: '레벨2',
    3: '레벨3',
    4: '레벨4',
    5: 'VIP',
  },
  gameList: {
    ALL: '전체',
    DG: '드림게이밍',
    DGCROWN: '드림게이밍 크라운',
    EVO: '에볼루션',
    OGP: '오리엔탈 플러스',
    88: '88',
    CMB: 'DK 카지노',
    CGY: '카가얀',
    LMC: '용문',
    AG: '아시안',
    CLIB: '클립',
    LMCLUB: 'LMCLUB',
    MONEY_TRANSFER: '머니 이동',
    DD: '더블 드라곤',
  },
  gameLogoURL: {
    DG: 'logos/dg.png',
    DGCROWN: 'logos/dgcrown.png',
    EVO: 'logos/evo.png',
    OGP: 'logos/ogplus.png',
    CLIB: 'logos/clib.png',
  },
  lobby: {
    balanceLabel: '내 보유금액',
    gameType: {
      all: '모든타입',
      baccarat: '바카라',
      speedbaccarat: '스피드바카라',
      dragontiger: '용호',
      bullbull: '불불',
      SBAC: '스피드',
      BAC: '바카라',
      DT: '용호',
      LIGHTNING: '라이트닝',
      KOREANBAC: '한국딜러',
      BULL: '불불',
    },
    gameCategory: {
      // Categories
      SOLAIRE: '솔레어',
      CAGAYAN : '카가얀',
      STOTSENBERG : '스토첸버그',
      OKADA : '오카다',
      RESORTSWORLD : '리조트월드',
      WM : 'WM',
      EURO: '유럽',
      FLAGSHIP: '플래그쉽',
      CROWN: '드림 크라운',
      GLOBAL: '드림 글로벌',
      RW_PINK_DOLPHIN: '리조트 핑크',
      RW_TIGER: '리조트 타이거'
    },
    minimumBet: 'Minimum Bet:',
    maximumBet: 'Maximum Bet:',
    playerBetLimit: '플레이어 베팅 한도:',
    bankerBetLimit: '뱅커 베팅한도:',
    tieBetLimit: '타이 베팅한도:',
    pairBetLimit: '페어 베팅한도:',
    baccarat: '방 입장',
    cardShuffle: "셔플링",
  },
  gameroom: {
    closeTextHeader: '게임 준비중',
    closeTextTop: '현재 게임을 준비하고 있습니다.',
    closeTextBot: '잠시만 기다려주시면 감사하겠습니다.',
    betLimitText: '베팅한도: ',
    playerBetLimitText: 'P베팅한도: ',
    tieBetLimitText: 'T베팅한도: ',
    bankerBetLimitText: 'B베팅한도: ',
    pairBetLimitText: 'PP베팅한도: ',
    bullBetLimitText: 'BB베팅한도: ',
  },
  shoeArea: {
    banker: '뱅커',
    player: '플레이어',
    dragon: '드래곤',
    tiger: '타이거',
  },
  game: {
    balance: '잔액',
    round: '회차',
    totalBet: '총 베팅금액',
    player: '플레이어',
    playerPair: '플레이어 페어',
    player_pair: '플레이어 페어',
    banker: '뱅커',
    bankerPair: '뱅커 페어',
    banker_pair: '뱅커 페어',
    tie: '타이',
    dtplayer: '타이거',
    dtbanker: '드래곤',
    dttie: '타이',
    p1: '플레이어1',
    p2: '플레이어2',
    p3: '플레이어3',
    b1: '뱅커1',
    b2: '뱅커2',
    b3: '뱅커3',
    p1b: '플레이어1불불',
    p2b: '플레이어2불불',
    p3b: '플레이어3불불',
    b1b: '뱅커1불불',
    b2b: '뱅커2불불',
    b3b: '뱅커3불불',
    playerRate: '1:1',
    playerPairRate: '1:11',
    bankerRate: '1:0.95',
    bankerPairRate: '1:11',
    tieRate: '1:8',
    tieRateDt: '1:11',
    tigerRate: '1:0.97',
    dragonRate: '1:0.97',
    sendBet: '베팅 완료',
    clearBet: '베팅 삭제',
    cancelBet: '베팅 취소',
    moveToLobyButton: '테이블 변경',
    bettingMessage: {
      open: '베팅 해주세요',
      closed: '베팅 정지',
      resultShow: '결과',
      shuffling: 'Shuffling',
    },
    dragon: '드래곤',
    tiger: '타이거',
    bettingNotification: {
      bettingSuccessful: '베팅  완료',
      bettingFailed: '베팅 실패',
      playerBankerConflict: '플레이어 및 뱅커 동시에 베팅 할 수 없습니다',
      bettingCleared: '베팅 최소',
      bettingCancelled: '베팅 취소',
      bettingCancelFail: '베팅 취소 실패',
      NO_BET: '내기 없음',
      BET_CANCEL_SUCCESS: '베팅 취소',
      ROOM_NOT_BET_OPEN: '현재 마감 된 베팅',
      INSUFFICIENT_BALANCE: '잔액 불충분',
      INVALID_BETAMOUNT_DUE_TO_LIMIT: '베팅 한도 도달',
      ERROR_OCCURED: '오류가 발생했습니다',
      AGENT_API_CALL_FAILED: '베팅 실패',
    },
    chipCurrency: {
      KRW: 'KRW',
      USD: 'USD',
      KRW1000: '천',
      KRW10000: '만',
      USD1000: 'K',
    },
    cardResult: {
      jack: 'J',
      queen: 'Q',
      king: 'K',
      playerResult: '플레이어가',
      bankerResult: '벵커',
      tieResult: '타이',
      leftPanelLabel: 'P',
      rightPanelLabel: 'B',
      leftPanelLabelDT: 'Tr',
      rightPanelLabelDT: 'Dr',
    },
    result: {
      banker: 'banker',
      bankerBankerPair: 'bankerBankerPair',
      bankerPlayerPair: 'bankerPlayerPair',
      bankerBankerPlayerPair: 'bankerBankerPlayerPair',
      player: 'player',
      playerBankerPair: 'playerBankerPair',
      playerPlayerPair: 'playerPlayerPair',
      playerBankerPlayerPair: 'playerBankerPlayerPair',
      tie: 'tie',
      tieBankerPair: 'tieBankerPair',
      tiePlayerPair: 'tiePlayerPair',
      tieBankerPlayerPair: 'tieBankerPlayerPair',
    },
    errorMessage: {
      BANKER_BET_CONFLICT: '플레이어와 함께 베팅 할 수 없습니다.',
      PLAYER_BET_CONFLICT: '뱅커와 함께 베팅 할 수 없습니다.',
      BET_CLOSED: '베팅이 마감되었습니다.',
      INSUFFICIENT_FUNDS: '자금 부족',
      NO_BET: '칩을 올린 뒤 베팅완료 를 눌러주세요.',
      INVALID_BETAMOUNT_DUE_TO_LIMIT: '베팅 한도에 도달했습니다.',
      CANCEL_OVERTIME: 'OVERTIME',
    },
    video: {
      marqueeText:
        '※현지 기상 및 인터넷 상태에 따라 영상의 싱크가 맞지 않을수 있으니 영상 하단 갱신버튼을 활용해주세요 모든 결과는 영상 아래 본매점을 기준으로 처리됩니다.※',
    },
    tableSetting: '방 설정',
    moveToLoby: '로비 나가기',
    changeTable: '테이블 변경',
    liveBaccarat: '실시간 바카라',
  },
  betHistory: {
    round: '회차',
    table: '테이블',
    betHistoryTitle: '베팅 내역',
    Username: '유저아이디',
    Game: '게임',
    Bet: '벳',
    BetAmount: '베팅금액',
    Status: '상태',
    WinAmount: '배당금',
    Result: '결과',
    Card: '카드',
    BetBefore: '베팅 전',
    BetAfter: '베팅 후',
    CreatedAt: '날짜',
    UnprocessedState: '미처리',
    BetWinState: '적중',
    BetLoseState: '비적중',
    BetTieState: '타이 환급',
    BetRefundState: '환급',
    show: 'View',
    showDetails: 'Show',
  },
  gameState: {
    readyBetting: 'Game ready',
    nextBetting: '다음회차 부터 베팅이 가능합니다',
    startBetting: '베팅을 하여 주십시오',
    stopBetting: '베팅이 종료되었습니다',
    playerWin: '플레이어가 이겼습니다',
    bankerWin: '벵커가 이겼습니다',
    tigerWin: '타이거가 이겼습니다',
    dragonWin: '드래곤 이겼습니다',
    tieWin: '타이가 이겼습니다',
  },
  moneyTransfer: {
    DEPOSIT: '입금',
    WITHDRAWAL: '출금',
  },
};
