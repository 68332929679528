import Vue from 'vue';
import App from './App.vue';
import router from './routes/router';
import store from './store/vuex';
import i18n from './i18n/i18n';
window.$ax = require('axios');

/**
 * Plugins
 *
 */

// Vue Boostrap
import BoostrapVue from 'bootstrap-vue';
Vue.use(BoostrapVue);

// Vue Moment
import VueMoment from 'vue-moment';
Vue.use(VueMoment);

// Vue-currency-filter
import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter, {
  symbol: '₩',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});

// Lodash
import lodash from 'lodash'
window._ = lodash

// Vue Cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

Vue.config.productionTip = true;
// Craft new application
new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Vue.prototype.$getLasttIndex = function(array, x) {
  var last = [...array[x]].reverse().find((curr) => curr.shoe);
  return array[x].lastIndexOf(last);
};
Vue.prototype.$getLastResultRoadtIndex = function(array, x) {
  var last = [...array[x]].reverse().find((curr) => curr);
  return array[x].lastIndexOf(last);
};
Vue.prototype.$prevShoeArr = function(s) {
  var banker = ['a', 'b', 'c', 'd'],
    player = ['e', 'f', 'g', 'h'],
    r = [];
  if (banker.indexOf(s) !== -1) {
    r = banker;
  }

  if (player.indexOf(s) !== -1) {
    r = player;
  }

  return r;
};

Vue.prototype.$countArr = function(array) {
  // Count the objects where shoe is not empty
  var count = array.reduce((acc, cur) => {
    if (cur.shoe !== '' && cur.isNextCol === 0) {
      acc += 1;
    }
    return acc;
  }, 0);
  return count;
};


Vue.prototype.$countArrBigEye = function(array) {
  // Count the objects where shoe is not empty
  var count = array.reduce((acc, cur) => {
    if (cur.shoe !== '') {
      acc += 1;
    }
    return acc;
  }, 0);
  return count;
};


Vue.prototype.$shoePredict = function(arr, k, i) {
  var banker = ['a', 'b', 'c', 'd'],
    player = ['e', 'f', 'g', 'h'],
    ret = '',
    pf = 0,
    ps = 0;

  if (i == 0) {

    pf = this.$countArrBigEye(arr[k - 1]);
    ps = this.$countArrBigEye(arr[k - 2]);

    if (pf > 0 && ps > 0) {
      if (pf == ps) {
        ret = 'a';
      } else {
        ret = 'e';
      }
    }
  }
  if (i >= 1) {
    pf = arr[k - 1][i].shoe;
    ps = arr[k - 1][i - 1].shoe;

    if (
      (banker.indexOf(this.$getShoe(pf)) !== -1 &&
        banker.indexOf(this.$getShoe(ps)) !== -1) ||
      (pf == '' && ps == '')
    ) {
      ret = 'a';
    } else if (
      (player.indexOf(this.$getShoe(pf)) !== -1 &&
        player.indexOf(this.$getShoe(ps)) !== -1) ||
      (pf == '' && ps == '')
    ) {
      ret = 'a';
    } else {
      ret = 'e';
    }
  }

  return ret;
};

Vue.prototype.$shoePredictTwo = function(arr, k, i) {
  var banker = ['a', 'b', 'c', 'd'],
    player = ['e', 'f', 'g', 'h'],
    ret = '',
    pf = 0,
    ps = 0;

  if (i == 0) {
    pf = this.$countArr(arr[k - 1]);
    ps = this.$countArr(arr[k - 3]);
    if (pf > 0 && ps > 0) {
      if (pf == ps) {
        ret = 'a';
      } else {
        ret = 'e';
      }
    }
  }
  if (i >= 1) {
    pf = arr[k - 2][i].shoe;
    ps = arr[k - 2][i - 1].shoe;

    if (
      (banker.indexOf(this.$getShoe(pf)) !== -1 &&
        banker.indexOf(this.$getShoe(ps)) !== -1) ||
      (pf == '' && ps == '')
    ) {
      ret = 'a';
    } else if (
      (player.indexOf(this.$getShoe(pf)) !== -1 &&
        player.indexOf(this.$getShoe(ps)) !== -1) ||
      (pf == '' && ps == '')
    ) {
      ret = 'a';
    } else {
      ret = 'e';
    }
  }

  return ret;
};

Vue.prototype.$shoePredictThree = function(arr, k, i) {
  // get the 2 previous columns of the bigroad array.
  var banker = ['a', 'b', 'c', 'd'],
    player = ['e', 'f', 'g', 'h'],
    ret = '',
    pf = 0,
    ps = 0;

  if (i == 0) {
    pf = this.$countArr(arr[k - 1]);
    ps = this.$countArr(arr[k - 4]);

    if (pf > 0 && ps > 0) {
      if (pf == ps) {
        ret = 'a';
      } else {
        ret = 'e';
      }
    }
  }
  if (i >= 1) {
    pf = arr[k - 3][i].shoe;
    ps = arr[k - 3][i - 1].shoe;

    if (
      (banker.indexOf(this.$getShoe(pf)) !== -1 &&
        banker.indexOf(this.$getShoe(ps)) !== -1) ||
      (pf == '' && ps == '')
    ) {
      ret = 'a';
    } else if (
      (player.indexOf(this.$getShoe(pf)) !== -1 &&
        player.indexOf(this.$getShoe(ps)) !== -1) ||
      (pf == '' && ps == '')
    ) {
      ret = 'a';
    } else {
      ret = 'e';
    }
  }

  return ret;
};

Vue.prototype.$getShoe = function(s) {
  var r = '';
  if (s != '') {
    r = s.split('-')[0];
  }
  return r;
};

// Filter for Shoe Text
Vue.filter('shoeText', (val) => {
  const bankerShoe = ['a', 'b', 'c', 'd'],
    playerShoe = ['e', 'f', 'g', 'h'],
    tieShoe = ['i', 'j', 'k', 'l'];

  if (bankerShoe.includes(val)) return 'B';
  else if (playerShoe.includes(val)) return 'P';
  else if (tieShoe.includes(val)) return 'T';
});

Vue.filter('shoeTextDT', (val) => {
  const bankerShoe = ['a', 'b', 'c', 'd'],
    playerShoe = ['e', 'f', 'g', 'h'],
    tieShoe = ['i', 'j', 'k', 'l'];

  if (bankerShoe.includes(val)) return 'Dr';
  else if (playerShoe.includes(val)) return 'Tr';
  else if (tieShoe.includes(val)) return 'T';
});

Vue.prototype.$res = function(s) {
  var banker = ['a', 'b', 'c', 'd'],
    player = ['e', 'f', 'g', 'h'],
    tie = ['i', 'j', 'k', 'l'];
  if (banker.indexOf(s) !== -1) {
    return 'a';
  } else if (player.indexOf(s) !== -1) {
    return 'e';
  } else if (tie.indexOf(s) !== -1) {
    return 'i';
  }
};

Vue.prototype.$converKRW = function(d) {
  var v = d;
  if (v >= 10000) {
    v = v / 10000 + '만';
  }
  return v;
};
