export default {
  lang: {
    english: 'English',
    korean: '한국어',
    chinese: '中文',
  },
  publicImgUrl: '/assets/images/',
  level: {
    1: 'Level 1',
    2: 'Level 2',
    3: 'Level 3',
    4: 'Level 4',
    5: 'VIP',
  },
  gameList: {
    ALL: 'ALL',
    DG: 'DREAM',
    DGCROWN: 'DREAM CROWN',
    EVO: 'EVO',
    OGP: 'OGPLUS',
    88: '88',
    CMB: 'CMB',
    CGY: 'CAG',
    LMC: 'LMC',
    AG: 'ASIA',
    CLIB: 'CLIB',
    LMCLUB: 'LMCLUB',
    MONEY_TRANSFER: 'TRANSACTION',
  },
  lobby: {
    balanceLabel: '我的持有量',
    gameType: {
      all: '所有类型',
      baccarat: '百家乐',
      speedbaccarat: '速度百家乐',
      dragontiger: '龙浩',
      bullbull: '불불',
      KOREANBAC: 'KOREAN TRADER',
      NORMAL: 'NORMAL',
      SPEED: 'SPEED BACCARAT',
      LIGHTNING: 'LIGHTNING'
    },
    gameCategory: {
      SOLAIRE: 'Solaire',
      CAGAYAN: 'Cagayan',
      STOTSENBERG: 'Stotsenberg',
      OKADA: 'OKADA',
      RESORTSWORLD: 'Resorts World',
      WM: 'WM',
      EURO: 'EURO',
      FLAGSHIP: 'FLAGSHIP',
      CROWN: 'CROWN',
      GLOBAL: 'GLOBAL'
    },
    minimumBet: '最低下注:',
    maximumBet: '最大赌注:',
    playerBetLimit: '玩家最小〜最大:',
    bankerBetLimit: '银行家Min〜Max:',
    tieBetLimit: '铁民〜最大:',
    pairBetLimit: '配对最小〜最大:',
    baccarat: '房间进入',
    cardShuffle: "卡洗牌",
  },
  gameroom: {
    closeTextHeader: '准备游戏',
    closeTextTop: '我们目前正在准备游戏。',
    closeTextBot: '如果您能稍等片刻，我将不胜感激。',
    betLimitText: '投注限额: ',
    playerBetLimitText: 'P投注限制: ',
    tieBetLimitText: 'T投注限额: ',
    bankerBetLimitText: 'B投注限额: ',
    pairBetLimitText: 'PP投注限额: ',
  },
  shoeArea: {
    banker: '银行家',
    player: '播放器',
    dragon: '龙',
    tiger: '老虎',
  },
  game: {
    balance: '平衡',
    totalBet: '总投注额',
    player: '播放器',
    playerPair: '球员对',
    player_pair: '球员对',
    banker: '银行家',
    bankerPair: '银行家公平',
    banker_pair: '银行家公平',
    tie: '领带',
    dtplayer: '老虎',
    dtbanker: '龙',
    dttie: '领带',
    p1: 'PLAYER1',
    p2: 'PLAYER2',
    p3: 'PLAYER3',
    b1: 'BANKER1',
    b2: 'BANKER2',
    b3: 'BANKER3',
    p1b: 'P1 B.B',
    p2b: 'P2 B.B',
    p3b: 'P2 B.B',
    b1b: 'B1 B.B',
    b2b: 'B2 B.B',
    b3b: 'B3 B.B',
    playerRate: '1:1',
    playerPairRate: '1:11',
    bankerRate: '1:0.95',
    bankerPairRate: '1:11',
    tieRate: '1:8',
    tieRateDt: '1:11',
    tigerRate: '1:0.97',
    dragonRate: '1:0.97',
    sendBet: '投注完成',
    clearBet: '删除投注',
    cancelBet: '投注',
    moveToLobyButton: 'Table change',
    bettingMessage: {
      open: '投注开放',
      closed: '投注关闭',
      resultShow: '结果显示',
      shuffling: '改组',
    },
    dragon: '龙',
    tiger: '老虎',
    bettingNotification: {
      bettingSuccessful: '投注成功',
      bettingFailed: '投注失败',
      playerBankerConflict: '玩家与银行家冲突',
      bettingCleared: '投注清除',
      bettingCancelled: '投注取消',
      bettingCancelFail: '无法取消投注',
      ROOM_NOT_BET_OPEN: '投注目前关闭',
      NO_BET: '不打赌',
      BET_CANCEL_SUCCESS: '投注取消',
      INSUFFICIENT_BALANCE: '余额不足',
      INVALID_BETAMOUNT_DUE_TO_LIMIT: '达到下注限制',
      ERROR_OCCURED: '发生错误',
    },
    chipCurrency: {
      KRW: 'KRW',
      USD: 'USD',
      CNY: 'CNY',
      KRW1000: '천',
      KRW10000: '만',
      USD1000: 'K',
    },
    cardResult: {
      jack: 'J',
      queen: 'Q',
      king: 'K',
      playerResult: '播放器',
      bankerResult: '本克',
      tieResult: '领带',
      leftPanelLabel: 'P',
      rightPanelLabel: 'B',
      leftPanelLabelDT: 'Tr',
      rightPanelLabelDT: 'Dr',
    },
    result: {
      banker: 'banker',
      bankerBankerPair: 'bankerBankerPair',
      bankerPlayerPair: 'bankerPlayerPair',
      bankerBankerPlayerPair: 'bankerBankerPlayerPair',
      player: 'player',
      playerBankerPair: 'playerBankerPair',
      playerPlayerPair: 'playerPlayerPair',
      playerBankerPlayerPair: 'playerBankerPlayerPair',
      tie: 'tie',
      tieBankerPair: 'tieBankerPair',
      tiePlayerPair: 'tiePlayerPair',
      tieBankerPlayerPair: 'tieBankerPlayerPair',
    },
    errorMessage: {
      BANKER_BET_CONFLICT: '无法与玩家一起下注',
      PLAYER_BET_CONFLICT: '不能和庄家一起下注',
      BET_CLOSED: '投注关闭',
      INSUFFICIENT_FUNDS: '不充足的资金',
      NO_BET: '不打赌',
      INVALID_BETAMOUNT_DUE_TO_LIMIT: '达到下注限制',
      CANCEL_OVERTIME: 'OVERTIME',
    },
    video: {
      marqueeText: '※根据当地天气和互联网状况，视频可能无法同步，请使用视频底部的更新按钮。',
    },
    tableSetting: '房间设置',
    moveToLoby: '退出大厅',
    changeTable: '表格变更',
    liveBaccarat: '实时百家乐',
  },
  betHistory: {
    round: '圆形的',
    table: '桌子',
    betHistoryTitle: 'Bet History',
    Username: '用户身份',
    Game: '游戏',
    Bet: '美国广播公司',
    BetAmount: '投注金额',
    Status: '状态',
    WinAmount: '股利',
    Result: '结果',
    Card: '卡片',
    BetBefore: '投注前',
    BetAfter: '投注后',
    CreatedAt: '日期',
    UnprocessedState: '未经处理',
    BetWinState: '打',
    BetLoseState: '错过了',
    BetTieState: 'Tie',
    BetRefundState: 'Refunded',
    show: 'View',
    showDetails: 'Show',
  },
  gameState: {
    readyBetting: 'Game ready',
    nextBetting: '您可以在下一轮下注。',
    startBetting: '下注',
    stopBetting: '投注结束',
    playerWin: '玩家获胜',
    bankerWin: '本克赢了',
    tieWin: '大元',
  },
};