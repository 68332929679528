<!--=========================================================================================
  File Name: App.vue
  Description: App
==========================================================================================-->
<template>
  <!-- <transition name="fade" mode="out-in"> -->
    <router-view :key="$route.fullPath"></router-view>
  <!-- </transition> -->
</template>

<style lang="scss">
// Main Stylesheet
@import './src/assets/scss/main';
@import './src/assets/scss/session';
@import './src/assets/scss/timer';
@import './src/assets/scss/type-D.scss';
@import './src/assets/scss/typed.scss';

// Cards SVG Stylesheet
@import './src/assets/scss/cards';

// Bootstrap Vue Stylesheet
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';

// Custom Scss
@import './src/assets/scss/custom';

// @import './src/assets/scss/mobile/GameLobbyMobile.scss';




</style>

<script>
export default {
  name: 'App'
}
</script>
